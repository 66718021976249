.PreviewGridClassContainer {
  width: 100% !important;
  margin: unset !important;
  justify-content: center;
}

.PreviewGridClassItem {
  padding: unset !important;
}

.dropzone-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropzone-container.with-image {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropzone-container.with-image .MuiDropzoneArea-textContainer {
  display: none;
}
