.with-markup {
  max-width: 700px;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.7em;
  color: rgba(0, 0, 0, 0.54);
  > p {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
  }
  > img {
    max-width: 100%;
  }
  a,
  a:hover,
  a:focus {
    color: #007bff;
    text-decoration: underline;
    background-color: transparent;
  }
}
