.rdw-link-decorator-wrapper {
  a,
  a:hover,
  a:focus {
    color: #007bff;
    text-decoration: underline;
    background-color: transparent;
  }
}

.rdw-link-decorator-icon {
  position: absolute;
  left: unset;
  right: -10%;
  top: -10px;
  cursor: pointer;
  width: 10%;
}

.rdw-image-imagewrapper {
  max-width: 100% !important;
}
