img {
  width: 100%;
}

/* atlas dark blue: #132f4a */
/* atlas light blue: #149cea */
h1 {
  font-size: 20px;
}

@import "https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700";

.StripeElement--focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.oaleadDates {
  display: inline-block;
}

.oaleadDates .react-datepicker__day--weekend {
  visibility: hidden;
}
.spinner {
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  height: 120px;
  width: 120px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(20, 156, 234, 0.15);
  border-right: 6px solid rgba(20, 156, 234, 0.15);
  border-bottom: 6px solid rgba(20, 156, 234, 0.15);
  border-top: 6px solid rgba(20, 156, 234, 0.99);
  border-radius: 100%;
  z-index: 1000;
  /* rgba(20,156,234,1) */
  /* rgba(19,47,74,1) */
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.form-group {
  margin-bottom: 0px;
}

.invalid-feedback {
  margin-top: 0px;
}

.landing {
  position: relative;
  background: url("./img/showcase.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 51px);
  margin-top: -24px;
}

.login .register .confirmation {
  position: relative;
  height: 100vh;
  margin-top: 75px;
}

.leadssel {
  min-height: 30px;
  margin-right: 5px;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #bbb;
}

/* html, body {
  height: 100%
} */
.footer {
  bottom: 50px;
  height: 55px;
  left: 0;
  right: 0;
}

/*
    DEMO STYLE
*/
body {
  font-family: "Quicksand", sans-serif;
  background-color: #f3f4f6 !important; /* #f7fbff; */
}

p {
  font-family: "Quicksand", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* .nav-item {
  font-size: 11px;
  border-bottom: 1px solid #1C4872;
}

.navbar {
  z-index: 998;
  padding: 9px 5px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none;
  border: none;
} */

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
/* .wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 125px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: #132f4a;
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -125px;
}

#sidebar .sidebar-header {
  height: 50px;
  padding: 10px;
  text-align: center;
  background: #149cea;
}

#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #7386D5;
  background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em;
  padding-left: 30px;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386D5;
}

a.article,
a.article:hover {
  background: #6d7fcc;
  color: #fff;
} */

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
/* #content {
  margin-top: 35px;
  width: calc(100%);
  padding: 0px;
  min-height: calc(100vh - 50px);
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100vh - 75px);
  overflow-y: auto;
}

#content.active {
  width: 100%;
} */

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
/* @media (max-width: 768px) {
    #sidebar {
        margin-left: -125px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #content {
        width: 100%;
    }
    #content.active {
        width: calc(100% - 125px);
    }
    #sidebarCollapse span {
        display: none;
    }
} */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}

#invimg {
  text-align: center;
  max-width: 65px;
  max-height: 65px;
  width: auto;
  height: auto;
}

.switch-toggle a,
.switch-light span span {
  display: none;
}

@media only screen {
  .switch-toggle {
    position: relative;
    display: block;
    padding: 0 !important;
  }

  .switch-toggle::after {
    clear: both;
    content: "";
    display: table;
  }

  .switch-toggle *,
  .switch-toggle *:before,
  .switch-toggle *:after {
    box-sizing: border-box;
  }

  .switch-toggle a {
    display: block;
    transition: all 0.2s ease-out;
  }

  .switch-toggle input:focus ~ span a,
  .switch-toggle input:focus + label {
    outline-width: 0px;
    outline-style: solid;
    outline-color: Highlight;
    /* remove Highlight
     */
  }
}

/* animation for btn wiggle */
@-webkit-keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  2% {
    -webkit-transform: rotate(-1deg);
  }
  3.5% {
    -webkit-transform: rotate(1deg);
  }
  5% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-o-keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  2% {
    -webkit-transform: rotate(-1deg);
  }
  3.5% {
    -webkit-transform: rotate(1deg);
  }
  5% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  2% {
    -webkit-transform: rotate(-1deg);
  }
  3.5% {
    -webkit-transform: rotate(1deg);
  }
  5% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-toggle input:focus ~ span a,
  .switch-toggle input:focus + label {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}

@media only screen {
  .switch-toggle input {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  .switch-toggle input + label {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    padding: 0 0.5em;
    margin: 0;
    text-align: center;
  }

  .switch-toggle a {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(4),
  .switch-toggle label:nth-child(2):nth-last-child(4) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(4) ~ a {
    width: 50%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(4)
    ~ input:checked:nth-child(3)
    + label
    ~ a {
    left: 50%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(6),
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ a {
    width: 33.33%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(6)
    ~ input:checked:nth-child(3)
    + label
    ~ a {
    left: 33.33%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(6)
    ~ input:checked:nth-child(5)
    + label
    ~ a {
    left: 66.66%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(8),
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ a {
    width: 25%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(8)
    ~ input:checked:nth-child(3)
    + label
    ~ a {
    left: 25%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(8)
    ~ input:checked:nth-child(5)
    + label
    ~ a {
    left: 50%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(8)
    ~ input:checked:nth-child(7)
    + label
    ~ a {
    left: 75%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(10),
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ a {
    width: 20%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(10)
    ~ input:checked:nth-child(3)
    + label
    ~ a {
    left: 20%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(10)
    ~ input:checked:nth-child(5)
    + label
    ~ a {
    left: 40%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(10)
    ~ input:checked:nth-child(7)
    + label
    ~ a {
    left: 60%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(10)
    ~ input:checked:nth-child(9)
    + label
    ~ a {
    left: 80%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(12),
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ a {
    width: 16.6%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(12)
    ~ input:checked:nth-child(3)
    + label
    ~ a {
    left: 16.6%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(12)
    ~ input:checked:nth-child(5)
    + label
    ~ a {
    left: 33.2%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(12)
    ~ input:checked:nth-child(7)
    + label
    ~ a {
    left: 49.8%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(12)
    ~ input:checked:nth-child(9)
    + label
    ~ a {
    left: 66.4%;
  }

  .switch-toggle
    label:nth-child(2):nth-last-child(12)
    ~ input:checked:nth-child(11)
    + label
    ~ a {
    left: 83%;
  }

  .switch-candy-blue a {
    background-color: #029cea;
  }

  /* iOS Theme
*/
  .switch-ios.switch-toggle {
    /* background-color: #D8D9DB; */
    border-style: solid;
    border-width: medium;
    border-color: #d8d9db;
    border-radius: 30px;
    /* box-shadow: inset rgba(0, 0, 0, 0.1) 1 1px 0; */
  }

  .switch-ios.switch-toggle a {
    background-color: #029cea;
    /* border: 0.125em solid #D8D9DB; */
    border-radius: 1.75em;
    transition: all 0.12s ease-out;
  }

  .switch-ios.switch-toggle label {
    height: 2.4em;
    color: #000000;
    line-height: 2.4em;
    vertical-align: middle;
  }

  .switch-ios input:checked + label {
    color: #ffffff;
  }

  /* Holo Theme
 */
  .switch-toggle.switch-holo,
  .switch-light.switch-holo > span {
    background-color: #464747;
    border-radius: 1px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
    color: #fff;
    text-transform: uppercase;
  }

  .switch-holo label {
    color: #fff;
  }

  .switch-holo > span span {
    opacity: 0;
    transition: all 0.1s;
  }

  .switch-holo > span span:first-of-type {
    opacity: 1;
  }

  .switch-holo > span span,
  .switch-holo label {
    font-size: 85%;
    line-height: 2.15625em;
  }

  .switch-holo a {
    background-color: #666;
    border-radius: 1px;
    box-shadow: inset rgba(255, 255, 255, 0.2) 0 1px 0,
      inset rgba(0, 0, 0, 0.3) 0 -1px 0;
  }

  /* Selected ON switch-light
*/
  .switch-holo.switch-light input:checked ~ span a {
    background-color: #0e88b1;
  }

  .switch-holo.switch-light input:checked ~ span span:first-of-type {
    opacity: 0;
  }

  .switch-holo.switch-light input:checked ~ span span:last-of-type {
    opacity: 1;
  }

  /* Material Theme
 */
  /* switch-light
 */
  .switch-light.switch-material a {
    top: -0.1875em;
    width: 1.75em;
    height: 1.75em;
    border-radius: 50%;
    background: #fafafa;
    box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.14),
      0 0.1875em 0.125em -0.125em rgba(0, 0, 0, 0.2),
      0 0.125em 0.25em 0 rgba(0, 0, 0, 0.12);
    transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .switch-material.switch-light {
    overflow: visible;
  }

  .switch-material.switch-light::after {
    clear: both;
    content: "";
    display: table;
  }

  .switch-material.switch-light > span {
    overflow: visible;
    position: relative;
    top: 0.1875em;
    width: 3.25em;
    height: 1.5em;
    min-height: auto;
    border-radius: 1em;
    background: rgba(0, 0, 0, 0.26);
  }

  .switch-material.switch-light span span {
    position: absolute;
    clip: rect(0 0 0 0);
  }

  .switch-material.switch-light input:checked ~ span a {
    right: 0;
    background: #3f51b5;
    box-shadow: 0 0.1875em 0.25em 0 rgba(0, 0, 0, 0.14),
      0 0.1875em 0.1875em -0.125em rgba(0, 0, 0, 0.2),
      0 0.0625em 0.375em 0 rgba(0, 0, 0, 0.12);
  }

  .switch-material.switch-light input:checked ~ span {
    background: rgba(63, 81, 181, 0.5);
  }

  /* switch-toggle
 */
  .switch-toggle.switch-material {
    overflow: visible;
  }

  .switch-toggle.switch-material::after {
    clear: both;
    content: "";
    display: table;
  }

  .switch-toggle.switch-material a {
    top: 48%;
    width: 0.375em !important;
    height: 0.375em;
    margin-left: 0.25em;
    background: #3f51b5;
    border-radius: 100%;
    transform: translateY(-50%);
    transition: transform 0.4s ease-in;
  }

  .switch-toggle.switch-material label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1em;
  }

  .switch-toggle.switch-material label:before {
    content: "";
    position: absolute;
    top: 48%;
    left: 0;
    display: block;
    width: 0.875em;
    height: 0.875em;
    border-radius: 100%;
    border: 0.125em solid rgba(0, 0, 0, 0.54);
    transform: translateY(-50%);
  }

  .switch-toggle.switch-material input:checked + label:before {
    border-color: #3f51b5;
  }

  /* ripple
 */
  .switch-light.switch-material > span:before,
  .switch-light.switch-material > span:after,
  .switch-toggle.switch-material label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: block;
    width: 4em;
    height: 4em;
    border-radius: 100%;
    background: #3f51b5;
    opacity: 0.4;
    margin-left: -1.25em;
    margin-top: -1.25em;
    transform: scale(0);
    transition: opacity 0.4s ease-in;
  }

  .switch-light.switch-material > span:after {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -1.25em;
  }

  .switch-toggle.switch-material label:after {
    width: 3.25em;
    height: 3.25em;
    margin-top: -0.75em;
  }

  @keyframes materialRipple {
    0% {
      transform: scale(0);
    }

    20% {
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  .switch-material.switch-light input:not(:checked) ~ span:after,
  .switch-material.switch-light input:checked ~ span:before,
  .switch-toggle.switch-material input:checked + label:after {
    animation: materialRipple 0.4s ease-in;
  }

  /* trick to prevent the default checked ripple animation from showing
 * when the page loads.
 * the ripples are hidden by default, and shown only when the input is focused.
 */
  .switch-light.switch-material.switch-light input ~ span:before,
  .switch-light.switch-material.switch-light input ~ span:after,
  .switch-material.switch-toggle input + label:after {
    visibility: hidden;
  }

  .switch-light.switch-material.switch-light input:focus:checked ~ span:before,
  .switch-light.switch-material.switch-light
    input:focus:not(:checked)
    ~ span:after,
  .switch-material.switch-toggle input:focus:checked + label:after {
    visibility: visible;
  }
}

/* Bugfix for older Webkit, including mobile Webkit. Adapted from
 * http://css-tricks.com/webkit-sibling-bug/
 */
@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 80em) {
  .switch-light,
  .switch-toggle {
    -webkit-animation: webkitSiblingBugfix infinite 1s;
  }
}

@-webkit-keyframes webkitSiblingBugfix {
  from {
    -webkit-transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

[id^='__lpform'] img {
  width: initial;
}

.wp-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.wp-style-button {
  border: 0;
  cursor: pointer;
  width: auto;
  font-size: 16px;
  padding: 15px 30px;
  font-weight: 600;
  border-radius: 6px 6px 6px 6px;
  font-family: 'Quicksand';
  box-shadow: none;
  text-decoration: none;
  line-height: 1;
  text-align: center;
  /* transition: all .3s; */
  box-sizing: border-box;
  display: inline-block;
}

.wp-style-button.primary {
  fill: #fff;
  color: #fff;
  background-color: #029cea;
}

/* .wp-style-button.primary:hover {
  transform: scale(1.1);
} */

.wp-style-button.primary-outline {
  fill: #029cea;
  color: #029cea;
  background-color: #fff;
  border: 2px solid #029cea;
}

.wp-style-button.primary-outline:hover {
  color: #fff;
  background-color: #029cea;
  border-color: #029cea;
}

.wp-style-apply-coupon-button {
  font-size: 13px;
  padding: 10px 15px;
}

.coupon-field-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.coupon-invalid-alert {
  color: red
}

.coupon-valid-alert {
  color: green
}

.apply-btn-container {
  display: flex;
  justify-content: flex-end;
}

.right-16 {
  position: absolute;
  right: 16px;
}

body.hide-fc #fc_frame {
  display: none;
}


